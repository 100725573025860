import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { App, Button, Checkbox, Divider, Form, Input, Modal, Result } from 'antd';
import _ from 'lodash';
import Link from 'next/link';
import Image from 'next/image';
import { useUserProvider } from '@/context/UserProvider';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithRedirect,
  OAuthProvider,
  signOut,
} from 'firebase/auth';
import { emailNotification, userUpdate } from '@/lib/service';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import { isEmailValid, ls_saveItem } from '@/shared/utils';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { useConfigProvider } from '@/context/ConfigProvider';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';

const Login = () => {
  const { t } = useTranslation('common');
  const { message } = App.useApp();
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const [isReadProtocol, setIsReadProtocol] = useState(true);
  const [isMailReception, setIsMailReception] = useState(true);
  const { isIPhone } = useConfigProvider();
  const { width } = useScreenSize();

  const {
    openLogin,
    onOpenLoginModal,
    isLoginView,
    onChangedLoginView,
    openLoginResult,
    onChangedLoginResult,
    onAddToken,
  } = useUserProvider();

  const getToken = async (id: string, signInMethod: string) => {
    const res = await userUpdate(id, router.locale);

    if (res?.data?.first_login) {
      if (res.code == 0) {
        ga_logEvent(EventName.user_register_Success);
      } else {
        ga_logEvent(EventName.user_register_Fail);
      }
    }

    if (res.code == 0) {
      onAddToken(res.data.token, signInMethod, id);

      onOpenLoginModal(false);
      // onChangedLoginResult(true);
      message.success(t('LoggedInSuccessfully'))
      ga_logEvent(EventName.user_Login_Success);
    } else {
      message.error(res.message);

      const auth = getAuth();
      signOut(auth);
    }
    setLoading(false);
  };

  const onFinish = (values: any) => {
    const auth = getAuth();
    setLoading(true);

    if (isLoginView) {
      signInWithEmailAndPassword(auth, values?.email, values?.password)
        .then((result) => {
          result.user.getIdToken().then((id) => {
            getToken(id, 'email');
            ga_logEvent(EventName.login, { method: 'Email' });
          });
        })
        .catch((error) => {
          onAddToken(undefined);
          message.error(t('Login_Failed_Error_Msg'));
          setLoading(false);
        });
    } else {
      if (!isReadProtocol) {
        message.warning(t('Non-consent agreement'));
        setLoading(false);
        return;
      }
      createUserWithEmailAndPassword(auth, values?.email, values?.password)
        .then((result) => {
          result.user.getIdToken().then((id) => {
            getToken(id, 'email');
            ga_logEvent(EventName.sign_up, { method: 'Email' });
          });
        })
        .catch((error) => {
          onAddToken(undefined);
          message.error(t('Sign_Up_Failed_Error_Msg'));
          setLoading(false);
        });
    }
    emailNotification(isMailReception).catch((error) => {
      message.error(error.message);
    });
  };

  const onAuth = (method: string) => {
    onOpenLoginModal(false);
    const auth = getAuth();
    let provider;

    if (method === 'Google') {
      provider = new GoogleAuthProvider();
    } else if (method === 'Apple') {
      provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({
        locale: router?.locale,
      });
    }

    if (width < 900 && !isIPhone) {
      onOpenLoginModal(false);
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          let credential;
          if (method === 'Google') {
            credential = GoogleAuthProvider.credentialFromResult(result);
          } else {
            credential = OAuthProvider.credentialFromResult(result);
          }
          const token = credential.accessToken;
          Cookies.set('accessToken', token, { expires: 7 });
          // ls_saveItem('accessToken', token);

          result.user.getIdToken().then((id) => {
            getToken(id, 'google');
            ga_logEvent(EventName.login, { method });
          });
        })
        .catch((error) => {
          onAddToken(undefined);
          message.error(t('Third_Login_Failed_Error_Msg').replace('x111x', method));
        });
    }
  };

  useEffect(() => {
    return () => {
      if (openLogin) {
        ga_logEvent(EventName.Login_View);
      }
    };
  }, [openLogin]);

  return (
    <>
      <Modal
        className={styles.mainContainer}
        open={openLogin}
        onCancel={() => onOpenLoginModal(false)}
        destroyOnClose={true}
      >
        <Form
          name="normal_login"
          className={styles.homeContent}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ padding: '20px 24px' }}
        >
          <div className={styles.titleContainer}>
            {isLoginView ? t('Sign in') : t('CreateAnAccount')}
          </div>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('pleaseInputYourEmail') },
              () => ({
                validator(_, value) {
                  if (!value || isEmailValid(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('pleaseInputYourEmail')));
                },
              }),
            ]}
          >
            <Input
              className={styles.inputItem}
              prefix={
                <Image
                  src={`/images/user/email-icon.webp`}
                  width={20}
                  height={20}
                  alt={'email'}
                  loading="lazy"
                />
              }
              placeholder={t('EmailAddress')}
            // type="email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: t('pleaseInputYourPassword') },
              () => ({
                validator(_, value) {
                  if (!value || (`${value}`?.length >= 6 && `${value}`?.length <= 16)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('PasswordShouldBe_6_16_Characters')));
                },
              }),
            ]}
          >
            <Input
              className={styles.inputItem}
              prefix={
                <Image
                  src={`/images/user/password-icon.webp`}
                  width={20}
                  height={20}
                  alt={'password'}
                  loading="lazy"
                />
              }
              type="password"
              placeholder={t('Password')}
            />
          </Form.Item>
          {!isLoginView && (
            <Form.Item
              name="repassword"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('theNewPasswordThatYouEnteredDoNotMatch')));
                  },
                }),
              ]}
            >
              <Input
                className={styles.inputItem}
                prefix={
                  <Image
                    src={`/images/user/password-icon.webp`}
                    width={20}
                    height={20}
                    alt={'password'}
                    loading="lazy"
                  />
                }
                type="password"
                placeholder={t('ConfirmPassword')}
              />
            </Form.Item>
          )}

          {!isLoginView && (
            <>
              <div className={styles.agreeDesc}>
                <Checkbox
                  checked={isReadProtocol}
                  onChange={() => setIsReadProtocol(!isReadProtocol)}
                />
                <div>
                  <span>{t('ByClickingContinue_Desc')}</span>
                  <span>
                    <Link
                      href={'/aboutus'}
                      className={styles.link}
                      onClick={() => onOpenLoginModal(false)}
                    >
                      {t('Terms')},
                    </Link>
                  </span>
                  <span>
                    <Link
                      href={'/privacy-policy'}
                      className={styles.link}
                      onClick={() => onOpenLoginModal(false)}
                    >
                      {t('privacyPolicy')}
                    </Link>
                  </span>
                </div>
              </div>
              <div className={styles.agreeDesc}>
                <Checkbox
                  checked={isMailReception}
                  onChange={() => setIsMailReception(!isMailReception)}
                />
                <span>{t('EmailNotifications')}</span>
              </div>
            </>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginFormButton}
              loading={loading}
            >
              {isLoginView ? t('Next') : t('Continue')}
            </Button>
          </Form.Item>

          {isLoginView && (
            <Form.Item>
              {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox className={styles.staySignedIn}>{t('StaySignedIn')}</Checkbox>
              </Form.Item> */}

              <Link
                className={styles.forgotItem}
                href="/forgot-password"
                onClick={() => {
                  onOpenLoginModal(false);
                  ga_logEvent(EventName.login_Forgot_Password);
                }}
              >
                {t('ForgotPassword')}?
              </Link>
            </Form.Item>
          )}

          {isLoginView && (
            <Form.Item>
              <Button className={styles.createFormButton} onClick={() => onChangedLoginView(false)}>
                {t('CreateAnAccount')}
              </Button>
            </Form.Item>
          )}

          <Form.Item>
            <Divider className={styles.googleLoginText}>
              {isLoginView && <span>{t('Login')}</span>}
              {isLoginView && <span> {t('with others')}</span>}
              {!isLoginView && <span> {t('or create an account with')}</span>}
            </Divider>

            <Button className={styles.googleFormButton} onClick={() => onAuth('Google')}>
              <Image
                src={`/images/login/google_icon.webp`}
                width={20}
                height={20}
                alt={'google login'}
                loading="lazy"
              />
              <span>{t('Login with')}</span>
              <span>google</span>
            </Button>

            <Button
              className={styles.googleFormButton}
              style={{ marginTop: '15px' }}
              onClick={() => onAuth('Apple')}
            >
              <Image
                src={`/images/login/apple-login-icon.webp`}
                width={20}
                height={20}
                alt={'apple login'}
                loading="lazy"
              />
              <span>{t('Login with')}</span>
              <span>Apple</span>
            </Button>

            {!isLoginView && (
              <div className={styles.accountContainer}>
                <span>{t('AlreadyHaveAnAccount')} </span>
                <span onClick={() => onChangedLoginView(true)}>{t('Sign in')}</span>
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>

      {/* <Modal
        className={styles.mainContainer}
        open={openLoginResult}
        onCancel={() => onChangedLoginResult(false)}
        destroyOnClose={true}
      >
        <div className={styles.homeContent} style={{ padding: '20px 24px' }}>
          <Result
            status="success"
            title={t('LoggedInSuccessfully')}
            // subTitle={t('InTheFutureYouCanVerifyThroughEmailCode_Desc')}
            extra={[
              <Button
                type="primary"
                key="console"
                className={styles.loginFormButton}
                onClick={() => {
                  onChangedLoginResult(false);
                }}
              >
                {t('Continue')}
              </Button>,
            ]}
          />
        </div>
      </Modal> */}
    </>
  );
};

export default Login;
